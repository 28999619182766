import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { RouterLink } from "@angular/router";
import { AsyncPipe, NgIf } from "@angular/common";
import { CartFragment, GetFreeShippingStatusDocument } from "../../../common/gql/graphql";
import { DataService } from "../../../core/providers/data/data.service";
import { FreeShippingStatus } from "../../../common/generated-types";
import { StateService } from "../../../core/providers/state.service";
import { ActiveService } from "../../../core/providers/active-order/active.service";
import { FormatPricePipe } from "../../pipes/format-price.pipe";

@Component({
    selector: 'kb-free-shipping-notice',
    templateUrl: './free-shipping-notice.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        AsyncPipe,
        FormatPricePipe,
    ]
})
export class FreeShippingNoticeComponent implements OnInit {
    @Input() cart: CartFragment;
    @Input() fetchOnCartDrawerOpen = false;
    freeShippingStatus$: Observable<FreeShippingStatus & { routerLink: any[]; }> | null;
    constructor(
        private dataService: DataService,
        private stateService: StateService,
        private activeService: ActiveService,
    ) {}

    ngOnInit() {
        this.freeShippingStatus$ = this.activeService.activeOrder$.pipe(
            switchMap((activeOrder) =>
                this.fetchOnCartDrawerOpen
                    ? this.stateService
                          .select((state) => state.cartDrawerOpen)
                          .pipe(
                              filter((isOpen) => isOpen),
                              take(1),
                              map(() => activeOrder)
                          )
                    : of(activeOrder),
            ),
            switchMap((activeOrder) =>
                activeOrder ? this.dataService
                    .query(GetFreeShippingStatusDocument)
                    .pipe(map(({ checkFreeShippingStatus }) => ({
                        ...checkFreeShippingStatus,
                        routerLink: this.getRouterLink(checkFreeShippingStatus.region),
                    }))) : of(null),
            ),
        );
    }

    private getRouterLink(region: string | null) {
        if (region?.toLowerCase().includes('highlands')) {
            return ['/a', 'delivery-to-uk-highlands-islands'];
        }
        return ['/a', 'delivery-information'];
    }
}
